import React, { useEffect, useState } from "react";
import Main from "../components/layout/ConsultantMain";

import Consultanthistory from "../pages/ConsultantData";
import ConsultantChatbox from "../pages/ConsultantChatbox";
import { Route, Routes, useNavigate } from "react-router-dom";
import { socketsss } from "../components/layout/consultantSidenav";
import Incomingcall from "../pages/Incomingcall";
import Oncall from "../pages/OnCall";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { incomingCall } from "../redux/consultant/consultantAction";
import AudioVideoTest from "../pages/AudioVideoCallTest";
import Profile from "../pages/Profile";
import Myearnings from "../pages/Myearnings";
import { Modal } from "antd";

const Admin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isIncomingCall, setIsIncomingCall] = useState(false);
  const params = window.location;
  const ConsultantProfileData = JSON.parse(
    localStorage.getItem("Consultantprofile")
  );
  useEffect(() => {
    socketsss.emit("join-room", ConsultantProfileData?._id);

    socketsss.on("endcall", (data) => {
      console.log("call ended root", data);

      setTimeout(() => {
        navigate("/");
      }, 1000);
    });

    socketsss.on("call", (data) => {
      // setIsIncomingCall(true);
      dispatch(incomingCall(data));
      console.log("incoming call ................");
      if (params.hash == `#/incomingcall/${data.fromUser}/${data.meetingId}`) {
        console.log("already navigated");
      } else {
        navigate(`/incomingcall/${data.fromUser}/${data.meetingId}`);
      }
    });

    return () => {
      socketsss.off("call");
      socketsss.off("callbusy");
      socketsss.off("endcall");
    };
  }, []);

  useEffect(() => {
    socketsss.on("callbusy", (data) => {
      console.log("callbusy", data);
      socketsss.emit("busycallend", {
        toUser: data.fromUser,
        fromUser: data.toUser,
      });
    });
    return () => {
      socketsss.off("callbusy");
    };
  }, []);

  return (
    <Main>
      <Routes>
        <Route exact path="/" element={<Profile />} />
        <Route exact path="/chats" element={<ConsultantChatbox />} />
        <Route path="/test" element={<AudioVideoTest />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/my-earnings" element={<Myearnings />} />
        <Route
          exact
          path="/incomingcall/:id/:meetingid"
          element={<Incomingcall />}
        />
        <Route exact path="/oncall/:id/:meetingid" element={<Oncall />} />
      </Routes>
    </Main>
  );
};

export default Admin;

const CallModal = ({ children, isVisible }) => {
  if (isVisible) {
    return <div className="text-red-500 font-extrabold text-4xl">ABHAYA</div>;
  } else {
    return <>{children}</>;
  }
};
