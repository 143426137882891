import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Switch,
} from "antd";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useEffect, useRef, useState } from "react";
import moment from "moment";
import axios from "../../utils/api";
import { ConfirmationAlert } from "../../components/alert/allertbox";
const { Title } = Typography;
// table code start
const columns = [
  {
    title: "USER",
    dataIndex: "name",
    key: "name",
    width: "32%",
  },
  {
    title: "PHONE",
    dataIndex: "PHONE",
    key: "PHONE",
  },
  {
    title: "Redeem Amount",
    key: "priceperminute",
    dataIndex: "priceperminute",
  },
  {
    title: "Action",
    key: "status",
    dataIndex: "status",
  },

  {
    title: "Date",
    key: "Date",
    dataIndex: "Date",
  },
];

const ConsultantCoinRedeem = () => {
  const [ListData, setListData] = useState([]);
  const [status, setStatus] = useState(false);
  // const [page, setPage] = useState(1);
  const userId = useRef();
  let datavalue = ListData?.results?.map((w, i) => {
    return {
      key: i,
      name: (
        <>
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              shape="square"
              size={40}
              src={w?.consultantId?.image}
            ></Avatar>
            <div className="avatar-info">
              {/* <Title level={5}>{w?.about}</Title> */}
              <p>
                {w?.consultantId?.firstName} {w?.consultantId?.lastName}
              </p>
            </div>
          </Avatar.Group>{" "}
        </>
      ),
      PHONE: (
        <>
          <div className="author-info">
            <Title level={5}>{w?.consultantId?.phone}</Title>
            {/* <p>Organization</p> */}
          </div>
        </>
      ),
      avalibility: (
        <>
          <div className="author-info">
            <Title level={5}>{w?.consultantId?.availability}</Title>
            {/* <p>Organization</p> */}
          </div>
        </>
      ),
      status: (
        <>
          <Button
            type="primary"
            disabled={status}
            onClick={() => {
              setShowAlert(true);
              userId.current = w?._id;
            }}
            className="tag-primary"
            style={{
              background: status ? "#879" : "#A40165",
              borderColor: status ? "#879" : "#A40165",
            }}
          >
            Redeem Money
          </Button>
        </>
      ),
      priceperminute: (
        <>
          <div className="ant-employed">
            <Title level={5}>{w?.amount + "Rs"}</Title>
          </div>
        </>
      ),

      Date: (
        <>
          <div className="ant-employed">
            <Title level={5}>
              {moment(w?.createdAt).format("YYYY-MM-DD / h:m:s A")}
            </Title>
          </div>
        </>
      ),
    };
  });

  const getRedeemLists = async (page = 1) => {
    try {
      const { data } = await axios.get(
        `/coin-redeem/transaction?status=${status}&limit=10&page=${page}`
      );
      setListData(data);
    } catch (error) {
      console.log(error?.response?.data);
    }
  };

  useEffect(() => {
    getRedeemLists();
  }, [status]);
  const [showAlert, setShowAlert] = useState(false);
  const completeRedeem = async () => {
    try {
      const { data } = await axios.patch(
        `/coin-redeem/redeem-complete/${userId.current}`
      );
      console.log("Redeem response", data);
      toast("Payment Successful", { type: "success" });
      getRedeemLists();
    } catch (error) {
      console.log(error?.response?.data);
      toast(error?.response?.data?.message, { type: "error" });
    }
  };
  const handleConfirm = (id) => {
    completeRedeem(id);
    setShowAlert(false); // Hide the alert
  };

  const handleCancel = () => {
    setShowAlert(false); // Hide the alert
  };

  return (
    <>
      {showAlert && (
        <ConfirmationAlert
          message={"Have you made payment to user ?"}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Coin redeem Table"
              extra={
                <>
                  {/* <Input
                    className="header-search"
                    placeholder="Type here..."
                    prefix={<SearchOutlined />}
                  /> */}
                  <div>
                    <h1 className="text-sky-600 font-bold">
                      Switch to history
                    </h1>
                    <Switch
                      checked={status}
                      title="Switch to history"
                      onChange={() => setStatus(!status)}
                    />
                  </div>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={datavalue}
                  pagination={{
                    pageSize: 10,
                    hideOnSinglePage: false,
                    total: ListData?.total,
                  }}
                  className="ant-border-space"
                  onChange={(e) => {
                    getRedeemLists(e.current);
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </>
  );
};

export default ConsultantCoinRedeem;
