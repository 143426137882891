import {
  GET_USERLIST_REQUEST,
  GET_USERLIST_SUCCESS,
  GET_USERLIST_FAIL,
  GET_USERUPDATE_REQUEST,
  GET_USERUPDATE_SUCCESS,
  GET_USERUPDATE_FAIL,
  GET_USERDEATILS_REQUEST,
  GET_USERDEATILS_SUCCESS,
  GET_USERDEATILS_FAIL,
  CONSULTANT_MESSAGE_REQUEST,
  CONSULTANT_MESSAGE_SUCCESS,
  CONSULTANT_MESSAGE_FAIL,
  CONSULTANT_NEW_MESSAGE,
  CONSULTANT_NEW_MESSAGE_CLIENT,
  CONSULTANT_NEW_MESSAGE_CLEAR,
  CONSULTANT_MSG_CLEAR_LENGTH,
} from "./userConstant";

const initialState = {
  loading: false,
  activeUser: null,
  userList: [],
  updateuser: [],
  error: null,
  consultantChat: {},
};

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USERLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_USERLIST_SUCCESS:
      return {
        ...state,
        userList: payload,
        loading: false,
      };

    case GET_USERLIST_FAIL:
      return {
        ...state,
        loading: false,
        userList: [],
      };

    case GET_USERDEATILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_USERDEATILS_SUCCESS:
      return {
        ...state,
        activeUser: payload,
        loading: false,
      };
    case GET_USERDEATILS_FAIL:
      return {
        ...state,
        loading: false,
        userList: [],
      };
    case GET_USERUPDATE_REQUEST:
      return {
        ...state,
        // loading: true,
      };

    case GET_USERUPDATE_SUCCESS:
      return {
        ...state,
        // activeUser: payload,
        // updateuser: [],
      };
    case GET_USERUPDATE_FAIL:
      return {
        ...state,
        // loading: false,
        // activeUser: [],
        // updateuser: [],
      };
    case CONSULTANT_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CONSULTANT_MESSAGE_SUCCESS: {
      // console.log({ payload });
      return {
        ...state,
        loading: false,

        consultantChat: payload,
      };
    }
    case CONSULTANT_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,

        consultantChat: {},
      };
    case CONSULTANT_NEW_MESSAGE: {
      // console.log("yyyyyyy", payload.userid);
      const b = state.userList.find((e) => e.user?._id == payload.userid);
      const t = b.chatmsg.push(payload);
      const s = b.chatlength.push(payload);
      // const b = state.userList.find((e) => e?._id === payload.id);
      // if (b) {
      //   b.chatmsg.push(payload);
      //   b.chatlength.push(payload);

      //   // Remove the object from the array
      //   state.userList = state.userList.filter((e) => e._id !== payload.id);

      //   // Add it back to the beginning of the array
      //   state.userList.unshift(b);
      // }
      // console.log({TT: b });
      return {
        ...state,
      };
    }
    case CONSULTANT_NEW_MESSAGE_CLIENT: {
      // console.log({ss:payload});
      // const b = state.userList.find((e) => e?._id == payload.id);
      // const t = b.chatmsg.push(payload.data);
      // const s = b.chatlength.push(payload);
      const b = state.userList.find((e) => e?._id === payload.id);
      if (b) {
        b.chatmsg.push(payload.data);

        // Remove the object from the array
        state.userList = state.userList.filter((e) => e._id !== payload.id);

        // Add it back to the beginning of the array
        state.userList.unshift(b);
      }

      return {
        ...state,
      };
    }
    case CONSULTANT_NEW_MESSAGE_CLEAR: {
      // console.log({ss:payload});
      // const b = state.userList.find((e) => e._id == payload);
      // const t = b.chatmsg=[];
      const bs = state.userList.map((obj) => ({ ...obj, chatmsg: [] }));

      // console.log({bs})
      // console.log({ b });
      return {
        ...state,
        userList: bs,
      };
    }
    case CONSULTANT_MSG_CLEAR_LENGTH: {
      let b = state.userList?.find((e) => e?._id == payload);
      if (b) {
        b.chatlength = [];
      }
      return {
        ...state,
        // userList: bs,
      };
    }
    default:
      return state;
  }
};
