import React from "react";

const AudioWaveView = ({ micRef, isLocal }) => {
  return (
    <>
      {isLocal ? (
        <div className="relative grid place-items-center w-[35vw] h-72 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-lg shadow-lg overflow-hidden">
          {/* Audio Element */}
          <audio ref={micRef} autoPlay muted={isLocal} className="hidden" />

          {/* Circular Container */}
          <div className="w-24 h-24 grid place-items-center rounded-full bg-slate-200 shadow-[8px_8px_16px_rgba(0,0,0,0.2),-8px_-8px_16px_rgba(255,255,255,0.6)]">
            <h1 className="text-3xl pt-4 font-extrabold text-black">A</h1>
          </div>

          {/* Decorative Glow Effect */}
          <div className="absolute inset-0 blur-2xl">
            <div className="w-44 h-44 rounded-full bg-purple-500 opacity-30 absolute top-16 left-10 animate-pulse"></div>
            <div className="w-36 h-36 rounded-full bg-pink-500 opacity-20 absolute bottom-10 right-10 animate-pulse"></div>
          </div>
        </div>
      ) : (
        <div className="relative grid place-items-center w-[35vw] h-72 bg-gradient-to-br from-green-400 to-teal-500 rounded-lg shadow-lg overflow-hidden">
          {/* Glass Effect */}
          <div className="absolute inset-0 bg-gradient-to-r from-white/30 to-white/10 backdrop-blur-lg rounded-lg border border-white/20"></div>

          {/* Audio Element */}
          <audio ref={micRef} autoPlay muted={isLocal} className="hidden" />

          {/* Inner Box */}
          <div className="relative w-24 h-24 grid place-items-center rounded-full bg-white/10 backdrop-blur-md shadow-[4px_4px_12px_rgba(0,0,0,0.4),-4px_-4px_12px_rgba(255,255,255,0.2)] border border-teal-300">
            <h1 className="text-3xl pt-4 font-extrabold text-black">B</h1>
          </div>

          {/* Decorative Highlights */}
          <div className="absolute w-40 h-40 bg-teal-300/20 rounded-full top-8 right-8 blur-3xl"></div>
          <div className="absolute w-32 h-32 bg-green-300/20 rounded-full bottom-8 left-8 blur-3xl"></div>
        </div>
      )}
    </>
  );
};

export default AudioWaveView;
