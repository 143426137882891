import { useNavigate } from "react-router-dom";

import { Layout, Button, Row, Col, Typography, Form, Input, Image } from "antd";
import { userLoginAction } from "../redux/adminLogin/adminAction";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import icon from "../assets/images/icon.png";
const SignIn = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { Title } = Typography;
  const { Header, Content } = Layout;

  const [userCredential, setuserCredential] = useState({
    email: "",
    password: "",
  });
  console.log("userCredential", userCredential);

  const handelchange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setuserCredential({ ...userCredential, [name]: value });
  };
  console.log(userCredential);
  const onFinish = (values) => {
    dispatch(userLoginAction(userCredential));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const tokens = JSON.parse(localStorage.getItem("authorization"));
  useEffect(() => {
    if (tokens) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <></>
      <Layout className="layout-default layout-signin">
        <Header>
          <div className="header-col header-brand">
            <h5>Wanna Bae</h5>
          </div>
        </Header>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Sign In</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your email and password to sign in
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    name="email"
                    onChange={handelchange}
                    placeholder="Email"
                  />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input
                    name="password"
                    onChange={handelchange}
                    placeholder="Password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="tag-primary"
                    style={{
                      width: "100%",
                      background: "#A40165",
                      borderColor: "#A40165",
                    }}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <Image className="mt-20" src={icon} alt="" />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default SignIn;
