import { Row, Col, Card, Table, Button, Avatar, Typography, Input } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import UserLinesChart from "../components/chart/UserLinesChart";

import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getUserList, getUserLists } from "../redux/usersList/userAction";
import { getConsultantList } from "../redux/consultant/consultantAction";
import { useSelector } from "react-redux";
import moment from "moment";

import UsersEChart from "../components/chart/UserEchart";
import { getUserAnalytics } from "../redux/analytics/AnalytisAction";
import axios from "axios";
import "./ProfileForm.css"; // Import the CSS file
import { url } from "../utils/api";
const { Title } = Typography;

// table code start
const columns = [
  {
    title: "EMAIL",
    dataIndex: "EMAIL",
    key: "EMAIL",
  },
  {
    title: "PHONE",
    dataIndex: "PHONE",
    key: "PHONE",
  },

  {
    title: "STATUS",
    key: "status",
    dataIndex: "status",
  },
  // {
  //   title: "SUBSCRIPTION",
  //   key: "subscription",
  //   dataIndex: "subscription",
  // },
  {
    title: "CREATED AT",
    key: "Created",
    dataIndex: "Created",
  },
];

const Users = () => {
  const datas = useSelector((state) => state.user);
  // // const s=
  // console.log(datas);
  let datavalue = datas?.userList.map((w, i) => {
    return {
      key: i,

      EMAIL: (
        <>
          <div className="author-info">
            <Title level={5}>{w.email}</Title>
            {/* <p>Organization</p> */}
          </div>
        </>
      ),
      PHONE: (
        <>
          <div className="author-info">
            <Title level={5}>{w.phone}</Title>
            {/* <p>Organization</p> */}
          </div>
        </>
      ),

      status: (
        <>
          {w.blocked ? (
            <Button type="danger" className="danger">
              Blocked
            </Button>
          ) : (
            <Button
              // type="primary"
              type="primary"
              className="tag-primary"
              style={{ background: "#A40165", borderColor: "#A40165" }}
            >
              Active
            </Button>
          )}
        </>
      ),
      // subscription: (
      //   <>
      //     <div className="ant-employed">
      //       <span>Ends in 2 month</span>
      //     </div>
      //   </>
      // ),
      Created: (
        <>
          <div className="ant-employed">
            <span>{moment(w.createdAt).format("MMM Do YY")}</span>
            <Link to={`/consultantcreate/${w._id}`}>Create</Link>
          </div>
        </>
      ),
    };
  });
  const data = useSelector((state) => state?.analytics?.UseranalyticsData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserLists());
    // eslint-disable-next-line
  }, []);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    id: "",
    about: "",
    pricePerMinute: "",
    availability: "",
    communicationType: [],
    image: "",
    firstName: "",
    lastName: "",
    phone: "",
    category: "",
    wallet: 0,
  });

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle checkbox inputs
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      communicationType: checked
        ? [...prevState.communicationType, value]
        : prevState.communicationType.filter((type) => type !== value),
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    try {
      const token = localStorage.getItem("authorization");

      const config = {
        headers: {
          "Content-Types": "application/json",
          Authorization: JSON.parse(token),
        },
      };
      const response = await axios.post(
        url + "/consultant/create",
        formData,
        config
      );
      setFormData({
        id: "",
        about: "",
        pricePerMinute: "",
        availability: "",
        communicationType: [],
        image: "",
        firstName: "",
        lastName: "",
        phone: "",
        category: "",
        wallet: 0,
      });
      setErrorMessage("Added Successfully");

      console.log("Profile updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating profile:", error);
      setErrorMessage(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="tabled">
        <div className="form-container">
          <form onSubmit={handleSubmit} className="profile-form">
            <h2 className="form-title">Profile Information</h2>

            <div className="form-group">
              <label>id</label>
              <input
                type="text"
                name="id"
                value={formData.id}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label>About</label>
              <textarea
                name="about"
                value={formData.about}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Price Per Minute</label>
              <input
                type="number"
                name="pricePerMinute"
                value={formData.pricePerMinute}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Availability</label>
              <input
                type="text"
                name="availability"
                value={formData.availability}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Communication Type</label>
              <div className="checkbox-group">
                <label>
                  <input
                    type="checkbox"
                    value="phone"
                    checked={formData.communicationType.includes("phone")}
                    onChange={handleCheckboxChange}
                  />
                  Phone
                </label>

                <label>
                  <input
                    type="checkbox"
                    value="chat"
                    checked={formData.communicationType.includes("chat")}
                    onChange={handleCheckboxChange}
                  />
                  Chat
                </label>
              </div>
            </div>
            <div className="form-group">
              <label>Image URL</label>
              <input
                type="text"
                name="image"
                value={formData.image}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Category</label>
              <input
                type="text"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Wallet</label>
              <input
                type="number"
                name="wallet"
                value={formData.wallet}
                onChange={handleChange}
              />
            </div>
            <button type="submit" className="submit-button">
              Submit
            </button>
            <h1
              style={{
                color: errorMessage === "Added Successfully" ? "green" : "red",
                textAlign: "center",
              }}
            >
              {errorMessage}
            </h1>
            <h1></h1>
          </form>
        </div>
      </div>
    </>
  );
};

export default Users;
