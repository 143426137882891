import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { socketsss } from "../components/layout/consultantSidenav";
import "./s.css";
import { useDispatch } from "react-redux";
import { ClearincomingCall } from "../redux/consultant/consultantAction";
import { useSelector } from "react-redux";
import { FaPhoneAlt, FaTimes } from "react-icons/fa";
import "./testsockt/Receive.css";
const Incomingcall = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const navigate = useNavigate();
  const ConsultantProfileData = JSON.parse(
    localStorage.getItem("Consultantprofile")
  );
  const handelRejectCall = () => {
    socketsss.emit("endcall", {
      toUser: params.id,
      fromUser: ConsultantProfileData._id,
      consultantid: ConsultantProfileData._id,
      user: params.id,
    });
    socketsss.emit("endcalls", {
      toUser: ConsultantProfileData._id,
      fromUser: params.id,
      consultantid: ConsultantProfileData._id,
      user: params.id,
    });
    setTimeout(() => {
      dispatch(ClearincomingCall());
      navigate("/profile");
      // window.location.reload();
    }, 2000);
  };

  const handelAccpetCall = () => {
    socketsss.emit("accpetcall", {
      toUser: params.id,
      fromUser: ConsultantProfileData._id,
      perMinuteCoin: 0,
      consultantid: ConsultantProfileData._id,
      user: params.id,
    });
    navigate(`/oncall/${params.id}/${params.meetingid}`);
  };
  const datas = useSelector((state) => state?.consultant?.incomingCall);

  useEffect(() => {
    if (!datas) {
      navigate("/profile");
      // handelRejectCall();
      // window.location.reload();
    }

    return () => {};
  }, [datas]);

  useEffect(() => {
    socketsss.on("endcall", (data) => {
      console.log("call ended root", data);
      setTimeout(() => {
        navigate("/");
      }, 1000);
    });

    return () => {
      socketsss.off("endcall");
    };
  }, []);

  const IncomingCall = () => {
    return (
      <div className="modal-overlay">
        <div className="container1">
          <div className="incoming-call-container">
            <div className="">
              <div className="call-animation">
                <div className={`ring ring-1 animate`} />
                <div className={`ring ring-2 animate`} />
                <div className={`ring ring-3 animate`} />
                <div className="call-icon">
                  <i className="fas fa-phone-alt" />
                </div>
              </div>
              <div className="grid place-items-center mt-32 mb-10">
                <img className="caller-photo" src={datas?.profilePic} />
                <span className="text-white font-extrabold">
                  {datas?.name} is calling ...
                </span>
              </div>

              <div className="call-actions px-8 py-2">
                <button className="reject-btn" onClick={handelRejectCall}>
                  <FaTimes size={20} /> Reject
                </button>
                <button
                  className="accept-btn px-10 py-2"
                  onClick={handelAccpetCall}
                >
                  <FaPhoneAlt size={20} /> Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="modal-overlay">
      <IncomingCall />
    </div>
  );
};

export default Incomingcall;
