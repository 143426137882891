import React, { useEffect, useRef, useState } from "react";
import { addResponseMessage, toggleWidget } from "react-chat-widget";
import "./style.css";
import "react-chat-widget/lib/styles.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  consultantmsgclearlength,
  getconsultatMessageByroomId,
  getUserList,
  newmsgreciveconsultant,
  newmsgreciveconsultantclear,
  newmsgreciveconsultantclient,
} from "../redux/usersList/userAction";
import {
  getUserAnalytics,
  getUserProfileAnalytics,
} from "../redux/analytics/AnalytisAction";
import Chatcard from "../components/Chatcard";
import { socketsss } from "../components/layout/consultantSidenav";
import { sendConsultantMessage } from "../redux/consultant/consultantAction";
import moment from "moment";
const ConsultantChatbox = () => {
  const datas = useSelector((state) => state.user.userList);
  const selectedID = localStorage.getItem("selectedId");

  const findIndex = datas?.findIndex((i) => i?.user?._id === selectedID);

  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("Consultantprofile"));
  // console.log(userData);
  const [isconsultant, setisconsultant] = useState(false);
  const ConsultantProfileData = JSON.parse(
    localStorage.getItem("Consultantprofile")
  );
  useEffect(() => {
    socketsss.emit("join-room", ConsultantProfileData?._id);
    toggleWidget();
    dispatch(getUserList());
    dispatch(getUserProfileAnalytics());
    dispatch(getUserAnalytics());
    addResponseMessage("Welcome to this awesome chat!");
  }, []);

  const ind = localStorage.getItem("index");
  const [message, setmessage] = useState("");
  const [isOnline, setisOnline] = useState(false);
  const [index, setindex] = useState(Number(ind));
  const [lastindex, setlastindex] = useState("");
  const [selectedId, setselectedId] = useState("");

  const [messages, setmessages] = useState([]);

  const chatmessage = useSelector((state) => state.user?.consultantChat);

  const consultantProfile = JSON.parse(
    localStorage.getItem("Consultantprofile")
  );

  const [activechats, setactivechats] = useState("");
  const [incmsg, setincmsg] = useState(false);
  useEffect(() => {
    setmessages([]);
    setincmsg(false);
    setactivechats(userData?._id + selectedID);
    // dispatch(getUserList())
    return () => {};
  }, [index]);
  useEffect(() => {
    scrollToBottom();

    return () => {};
  }, [chatmessage]);

  useEffect(() => {
    socketsss.emit("status", ConsultantProfileData?._id);

    return () => {};
  }, []);

  useEffect(() => {
    socketsss.on("message", (data) => {
      setincmsg(true);
      setisconsultant(false);
      dispatch(newmsgreciveconsultant(data));
      addResponseMessage(data?.data);

      setmessages((oldArray) => [...oldArray, data]);
      scrollToBottom();
    });
    socketsss.on("status", (data) => {
      setisOnline(data);
    });

    return () => {
      socketsss.off("message");
      socketsss.off("status");
    };
  }, [datas[index]?._id]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const messagesEndRef = useRef(null);
  // console.log({ ppp: datas[index]?._id });
  // console.log(lastindex);
  const handelsubmit = () => {
    // if (datas[index]?.expoPushToken !== null) {
    //   sendMessageNotification({
    //     expoPushToken: datas[index]?.expoPushToken,
    //     firstName: "consultant",
    //     message: message.trim(),
    //     senderId: userData?._id, //profile id
    //   });
    // }

    // console.log(userData._id + datas[index]._id);
    // return;
    if (!message) {
      return;
    }
    setincmsg(true);
    setisconsultant(true);

    setmessages((oldArray) => [
      ...oldArray,
      {
        data: message,
        roomid: userData?._id + datas[index]?._id,
        userid: userData?._id,
      },
    ]);

    // if (datas[index].expoPushToken !== null) {
    //   dispatch(
    //     sendPushNotification({
    //       expoPushToken: datas[index].expoPushToken,
    //       firstName: `Consultant: ${consultantProfile?.firstName}`,
    //       message: message,
    //       someData: "Consultant",
    //     })
    //   );
    // }
    dispatch(
      newmsgreciveconsultantclient({
        data: {
          data: message,
          roomid: userData?._id + datas[index]?._id,
          userid: userData?._id,
        },
        id: datas[index]?._id,
      })
    );

    // console.log(message, datas[index]._id);
    socketsss.emit("message", {
      data: message.trim(),
      roomid: userData?._id + datas[index]?._id,
      userid: userData?._id,
    });
    sendConsultantMessage({
      data: message,
      roomid: userData?._id + datas[index]?._id,
      userid: userData?._id,
    }).then(() => {
      scrollToBottom();
    });

    setmessage("");
  };
  const handleKeyPress = (e) => {
    // Check if the Enter key (key code 13) was pressed
    if (e.key === "Enter") {
      // Trigger the button click event
      handelsubmit();
    }
  };
  //             socketsss.emit("join-room", userData?._id + e?._id),
  return (
    <div className="container">
      <div className="leftsidebar">
        {datas.map((e, index) => {
          socketsss.emit("join-room", userData?._id + e?._id);
          return (
            <Chatcard
              last={datas[index]}
              key={index}
              setlastindex={setlastindex}
              isconsultant={isconsultant}
              ind={ind}
              index={index}
              setindex={setindex}
              onClickUser={() => {
                dispatch(
                  getconsultatMessageByroomId(userData?._id + datas[index]?._id)
                );
                dispatch(newmsgreciveconsultantclear(datas[index]?._id));
                dispatch(consultantmsgclearlength(datas[index]?._id));
                setselectedId(e?.user?._id);
                localStorage.setItem("selectedId", e?.user?._id);
                socketsss.emit("status", e?.user?._id);
              }}
              e={e}
            />
          );
        })}
      </div>
      <div className="rightsidebar">
        <div className="header-box">
          <div className="row">
            <img
              className="circle-img"
              src={datas[findIndex]?.profilePic?.url}
              alt="d"
            />
            <div className="title-header">
              <h4>{datas[findIndex]?.firstName}</h4>
              <h5>{isOnline ? "Online" : "Offline"}</h5>
            </div>
          </div>
        </div>
        <div class="card">
          {chatmessage.length > 0 && (
            <div class="card-body">
              {chatmessage.map((e) => {
                return (
                  <>
                    {e?.dateChange && (
                      <div className="bg-gray-900/10">
                        <h1 className="text-teal-700 font-semibold">
                          {moment(e?.createdAt).format("DD, MMM, YYYY")}
                        </h1>
                      </div>
                    )}
                    {e?.userid !== userData?._id ? (
                      <div class="text-left">
                        <div className="name">
                          <span class="text" title="2020-01-01 09:00:00">
                            {e?.data}
                          </span>
                          <p className="text-[10px]">
                            {moment(e?.createdAt).format("hh:mm:ss A")}
                          </p>

                          <div className="title">
                            {datas[findIndex]?.firstName}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div class="text-right">
                        <div className="s">
                          <span class="text1" title="2020-01-01 09:00:00">
                            {e?.data}
                          </span>
                          <h5 className="text-[10px]">
                            {moment(e?.createdAt).format("hh:mm:ss A")}
                          </h5>
                          <h5 className="title">You</h5>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          )}

          {datas[findIndex]?.chatmsg.length > 0 && incmsg && (
            <div class="card-body">
              {datas[findIndex]?.chatmsg.map((e) => {
                // console.log({e});
                return e?.userid !== userData?._id ? (
                  <div class="text-left">
                    <div className="name">
                      <span class="text" title="2020-01-01 09:00:00">
                        {e?.data}
                      </span>
                      <div className="title">{datas[findIndex].firstName}</div>
                    </div>
                  </div>
                ) : (
                  <div class="text-right">
                    <div className="s">
                      <span class="text1" title="2020-01-01 09:00:00">
                        {e?.data}
                      </span>
                      <div className="title">You</div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="chatinput">
          <input
            value={message}
            onChange={(e) => {
              setmessage(e.target.value);
            }}
            onKeyUp={handleKeyPress}
            className="chatinp"
            type="text"
          />
          <button onClick={handelsubmit} className="chatsubmitbutton">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConsultantChatbox;
