export const data = [
  {
    id: "1",
    title: "Counselor",
  },
  {
    id: "2",
    title: "Therapist",
  },
  {
    id: "3",
    title: "Palm reader",
  },
  {
    id: "4",
    title: "Astrologer",
  },
  {
    id: "5",
    title: "Tarot reader",
  },
  {
    id: "6",
    title: "Psychologist",
  },
];
