import React, { useEffect, useState } from "react";
import { Card, Table, Row, Col, Tooltip } from "antd";
import {
  DollarOutlined,
  ClockCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";
import axios from "../utils/api";
import moment from "moment";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartTooltip,
  Legend
);

const EarningsDashboard = () => {
  const columns = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Call Duration (Minutes)",
      dataIndex: "callDuration",
      key: "callDuration",
      render: (text) => <span>{text} min</span>,
    },
    {
      title: "Rate Per Minute (₹)",
      dataIndex: "ratePerMinute",
      key: "ratePerMinute",
    },
    {
      title: "Total Earnings (₹)",
      dataIndex: "totalEarnings",
      key: "totalEarnings",
      render: (_, record) => (
        <Tooltip title="Calculated based on duration and rate">
          <span>{record.callDuration * record.ratePerMinute}</span>
        </Tooltip>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];
  const [calllist, setCallLists] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalAverageTime, setTotalAverageTime] = useState(0);
  const [pageMeta, setPageMeta] = useState(1);
  const [totalCall, setTotalCall] = useState(0);
  const getCallLists = async (page) => {
    try {
      const { data } = await axios.get(
        `/coin-transaction/call-list?limit=10&page=${page}`
      );
      // setPageMeta(data.meta);
      setTotalCall(data.meta?.total);
      const transformedData = data?.data?.map((item, index) => {
        const userName = `${item.userProfileId.firstName} ${item.userProfileId.lastName}`;
        const callDuration = item.totalDuration; // Assuming duration is in minutes
        const ratePerMinute = item.perMinuteCoin;
        const totalEarnings = callDuration * ratePerMinute;
        const month = moment(item.createdAt).format("DD-MM-YYYY");

        return {
          key: (index + 1).toString(),
          userName,
          callDuration,
          ratePerMinute,
          totalEarnings,
          date: month,
        };
      });
      // console.log("Call list", transformedData);

      setCallLists(transformedData);
    } catch (error) {
      console.error(error);
    }
  };

  const getTotalEarnings = async () => {
    try {
      const { data } = await axios.get("/coin-transaction/total-earings");
      setTotalEarnings(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getTotalAverageTime = async () => {
    try {
      const { data } = await axios.get("/coin-transaction/average-duration");
      setTotalAverageTime(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getCallLists();
    getTotalAverageTime();
    getTotalEarnings();
  }, []);

  return (
    <div style={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
      <Row gutter={[16, 16]}>
        {/* Metrics Cards */}
        <Col span={8}>
          <Card
            title="Total Earnings"
            bordered={false}
            style={{ textAlign: "center" }}
          >
            <DollarOutlined style={{ fontSize: "2rem", color: "#4caf50" }} />
            <h3>₹{totalEarnings}</h3>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Total Calls"
            bordered={false}
            style={{ textAlign: "center" }}
          >
            <UserOutlined style={{ fontSize: "2rem", color: "#2196f3" }} />
            <h3>{totalCall}</h3>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Average Call Duration"
            bordered={false}
            style={{ textAlign: "center" }}
          >
            <ClockCircleOutlined
              style={{ fontSize: "2rem", color: "#ffc107" }}
            />
            <h3>
              {totalAverageTime}
              min
            </h3>
          </Card>
        </Col>
      </Row>

      {/* Earnings Table and Chart */}
      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        <Col span={16}>
          <Card title="Call Details">
            <Table
              dataSource={calllist}
              columns={columns}
              pagination={{ pageSize: 10, total: totalCall }}
              bordered
              onChange={(e) => {
                console.log("Table Change:", e);
                getCallLists(e.current);
              }}
            />
          </Card>
        </Col>

        {/* Chart Section */}
        {/* <Col span={8}>
          <Card title="Earnings Distribution (Monthly)">
            <Bar
              data={chartData}
              options={{
                responsive: true,
                plugins: {
                  legend: { display: true, position: "bottom" },
                },
              }}
            />
          </Card>
        </Col> */}
      </Row>
    </div>
  );
};

export default EarningsDashboard;
