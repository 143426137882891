import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Row,
  Col,
  Card,
  Button,
  Descriptions,
  Avatar,
  Input,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";

import axios from "../utils/api";
import { FaCoins } from "react-icons/fa";
import { ConfirmationAlert } from "../components/alert/allertbox";

const { Option } = Select;

function Profile() {
  // const { id } = useParams();
  const dispatch = useDispatch();
  const { activeUser, loading } = useSelector((state) => state.user);

  // Dummy data for fallback
  // const dummyData = {
  //   about:
  //     "Experienced software developer and consultant with expertise in web and mobile development.",
  //   pricePerMinute: 50,
  //   availability: "Monday to Friday, 9 AM - 5 PM",
  //   communicationType: ["phone", "chat"],
  //   image:
  //     "https://images.ctfassets.net/7thvzrs93dvf/wpImage18536/5bad272ce24d9ce3b6b76a78ada6fa7b/abstract-pyrimid-upsplash.png?w=900&h=225&q=90&fm=png",
  //   firstName: "Shailesh",
  //   lastName: "Tiwari",
  //   phone: "+9536437629",
  //   category: "Software Development",
  // };

  const c = localStorage.getItem("Consultantprofile");
  const id = JSON.parse(c)?._id;
  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState();

  useEffect(() => {
    if (id) {
      getTotalEarnings();
    }
  }, []);

  const getTotalEarnings = async () => {
    try {
      const { data } = await axios.get(`/consultant/${id}`);
      console.log("Total earnings", data);
      // setTotalEarnings(data);
      setFormData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleSaveClick = async () => {
    setEditable(false);

    const body = {
      about: formData.about,
      pricePerMinute: formData.pricePerMinute,
      availability: formData.availability,
      communicationType: formData.communicationType,
      image: formData?.image,
      firstName: "Abhaya",
      lastName: "Sahoo",
      phone: "+1234567890",
      category: formData.category,
    };
    try {
      const { data } = await axios.put(`/consultant/${id}`, body);
      console.log("Total earnings", data);
      // setTotalEarnings(data);
      getTotalEarnings();
    } catch (error) {
      console.error(error);
    }

    // dispatch(updateUserDetails(activeUser?.data?.user?._id, formData));
  };

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  // curl -X 'GET' \
  // 'http://localhost:5001/consultant/67336c6b9609e37f62365f65' \
  // -H 'accept: */*' \
  // -H 'Authorization: Bearer
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFiaGF5YXNhaG9vbGtAZ21haWwuY29tIiwicGhvbmUiOjAsInN1YiI6IjY3MTc4NmY4YjczMjI0ZGFiNzk0NjFhMiIsImlhdCI6MTczMzgwNzIzN30.sZZ5N7kJdIwTHKM19rydIuD2zeuE5BOB-wzJaz-Em9o'

  const RequestRedeem = async () => {
    try {
      const { data } = await axios.post(`/coin-redeem/request`);
      console.log("Redeem response", data);
      toast("Redeem requested", { type: "success" });
    } catch (error) {
      console.log(error?.response?.data);
      toast(error?.response?.data?.message, { type: "error" });
    }
  };

  const [showAlert, setShowAlert] = useState(false);

  const handleConfirm = () => {
    RequestRedeem();
    setShowAlert(false); // Hide the alert
  };

  const handleCancel = () => {
    setShowAlert(false); // Hide the alert
  };

  return (
    <>
      {showAlert && (
        <ConfirmationAlert
          message="Are you sure you want to redeem your coins ?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      {!loading && (
        <div>
          <div
            className="profile-nav-bg"
            style={{
              backgroundImage:
                "url(" +
                "https://images.ctfassets.net/7thvzrs93dvf/wpImage18536/5bad272ce24d9ce3b6b76a78ada6fa7b/abstract-pyrimid-upsplash.png?w=900&h=225&q=90&fm=png" +
                ")",
            }}
          ></div>
          <Card
            className="card-profile-head"
            bodyStyle={{ display: "none" }}
            title={
              <Row justify="space-between" align="middle" gutter={[24, 0]}>
                <Col span={24} md={12} className="col-info">
                  <Avatar.Group>
                    <Avatar size={100} src={formData?.image} />
                    <div className="avatar-info">
                      <h4 className="font-semibold m-0">
                        {formData?.firstName} {formData?.lastName}
                      </h4>
                      <p>{formData?.category}</p>
                      <div className="flex my-4 items-center">
                        <FaCoins className="text-yellow-500 " size={40} />
                        <h1 className="px-4 text-lime-600 font-extrabold text-2xl">
                          {formData?.wallet}
                        </h1>
                      </div>
                    </div>
                  </Avatar.Group>
                </Col>
                <Col
                  span={24}
                  md={12}
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  {editable ? (
                    <button
                      className="bg-red-400 px-4 py-2 rounded-md"
                      onClick={handleSaveClick}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="bg-red-400 px-4 py-2  rounded-md"
                      onClick={handleEditClick}
                    >
                      Edit
                    </button>
                  )}
                  <button
                    disabled={!(formData?.wallet >= 50)}
                    onClick={() => {
                      setShowAlert(true); // Hide the alert
                    }}
                    className={`${
                      formData?.wallet >= 50 ? "bg-emerald-400" : "bg-gray-300"
                    }  my-4 rounded-md px-4 py-2 text-white`}
                  >
                    Redeem
                  </button>
                </Col>
              </Row>
            }
          ></Card>

          <Row gutter={[24, 0]}>
            <Col span={24} className="mb-24">
              <Card
                bordered={false}
                title={
                  <h6 className="font-semibold m-0">Profile Information</h6>
                }
                className="header-solid h-full card-profile-information"
                bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <Descriptions title="Details">
                  <Descriptions.Item label="About" span={3}>
                    {editable ? (
                      <Input.TextArea
                        value={formData?.about}
                        onChange={(e) => handleChange("about", e.target.value)}
                      />
                    ) : (
                      formData?.about
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="First Name" span={3}>
                    {editable ? (
                      <Input
                        value={formData?.firstName}
                        onChange={(e) =>
                          handleChange("firstName", e.target.value)
                        }
                      />
                    ) : (
                      formData?.firstName
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Last Name" span={3}>
                    {editable ? (
                      <Input
                        value={formData?.lastName}
                        onChange={(e) =>
                          handleChange("lastName", e.target.value)
                        }
                      />
                    ) : (
                      formData?.lastName
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Phone" span={3}>
                    {editable ? (
                      <Input
                        value={formData?.phone}
                        onChange={(e) => handleChange("phone", e.target.value)}
                      />
                    ) : (
                      formData?.phone
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Price Per Minute" span={3}>
                    {editable ? (
                      <Input
                        type="number"
                        value={formData?.pricePerMinute}
                        onChange={(e) =>
                          handleChange("pricePerMinute", e.target.value)
                        }
                      />
                    ) : (
                      `$${formData?.pricePerMinute}`
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Availability" span={3}>
                    {editable ? (
                      <Input
                        value={formData?.availability}
                        onChange={(e) =>
                          handleChange("availability", e.target.value)
                        }
                      />
                    ) : (
                      formData?.availability
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Communication Type" span={3}>
                    {editable ? (
                      <Select
                        defaultValue="lucy"
                        mode="multiple"
                        value={formData?.communicationType}
                        onChange={(value) =>
                          handleChange("communicationType", value)
                        }
                      >
                        <Option value="phone">Phone</Option>
                        {/* <Option value="video call">Video Call</Option> */}
                        <Option value="chat">Chat</Option>
                      </Select>
                    ) : (
                      formData?.communicationType.join(", ")
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default Profile;
