import { Row, Col, Card, Table, Avatar, Typography, Input } from "antd";

import { SearchOutlined } from "@ant-design/icons";

import UserLinesChart from "../components/chart/UserLinesChart";

import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  getconsultatMessageByroomId,
  getUserList,
} from "../redux/usersList/userAction";
import { useSelector } from "react-redux";
import moment from "moment";

import UsersEChart from "../components/chart/UserEchart";
import {
  getUserAnalytics,
  getUserProfileAnalytics,
} from "../redux/analytics/AnalytisAction";
import { addResponseMessage } from "react-chat-widget";
import { socketsss } from "../components/layout/consultantSidenav";
import { sendConsultantMessage } from "../redux/consultant/consultantAction";
const { Title } = Typography;

// table code start
const columns = [
  {
    title: "USER",
    dataIndex: "name",
    key: "name",
    width: "32%",
  },
  {
    title: "Gender",
    dataIndex: "Gender",
    key: "Gender",
  },
  {
    title: "showMe",
    dataIndex: "showMe",
    key: "showMe",
  },
  {
    title: "Age-Range",
    key: "agerange",
    dataIndex: "agerange",
  },
  // {
  //   title: "SUBSCRIPTION",
  //   key: "subscription",
  //   dataIndex: "subscription",
  // },
  {
    title: "City",
    key: "city",
    dataIndex: "city",
  },
  // {
  //   title: "Chat",
  //   key: "chat",
  //   dataIndex: "chat",
  // },
];

const Users = () => {
  const ConsultantProfileData = JSON.parse(
    localStorage.getItem("Consultantprofile")
  );

  const datas = useSelector((state) => state.user.userList);
  // console.log(datas);
  const [showchatbox, setshowchatbox] = useState(false);
  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);

    socketsss.emit(
      "message",
      {
        data: newMessage,
        roomid: ConsultantProfileData?._id + activeChat?._id,
        userid: ConsultantProfileData?._id,
      },
      (e) => {
        // console.log(e);
      }
    );
    sendConsultantMessage({
      data: newMessage,
      roomid: ConsultantProfileData?._id + activeChat?._id,
      userid: ConsultantProfileData?._id,
    });
    // Now send the message throught the backend API
  };

  const [activeChat, setactiveChat] = useState({});
  // console.log(activeChat);
  useEffect(() => {
    const p = datas?.map((w) => {
      joinChatroom(w);
    });

    return () => {};
  }, [datas]);
  const [lastmessageactive, setlastmessageactive] = useState({});

  let datavalue = datas?.map((w, i) => {
    return {
      key: i,
      name: (
        <>
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              shape="square"
              size={40}
              src={w?.profilePic?.url}
            ></Avatar>
            <div className="avatar-info">
              <Title level={5}>{w.firstName}</Title>
              <p>{w.user?.email}</p>
            </div>
          </Avatar.Group>{" "}
        </>
      ),
      Gender: (
        <>
          <div className="author-info">
            <Title level={5}>{w?.gender}</Title>
            {/* <p>Organization</p> */}
          </div>
        </>
      ),
      showMe: (
        <>
          <div className="author-info">
            <Title level={5}>{w?.showMe}</Title>
            {/* <p>Organization</p> */}
          </div>
        </>
      ),

      agerange: (
        <>
          <div className="ant-employed">
            <span>
              {/* {moment(w.createdAt).format("MMM Do Y")} */}
              <b>From</b>---
              {w.ageRange.from}
              <div></div>
              <b> To</b>---
              {w.ageRange.to}
            </span>
          </div>
        </>
      ),
      // chat: (
      //   <>
      //     <div className="App">
      //       <button
      //         onClick={() => {
      //           setactiveChat(w);
      //           setshowchatbox(true);
      //           toggleWidget();
      //           dropMessages();
      //         }}
      //       >
      //         CLICK
      //       </button>
      //       <div>dsd</div>
      //     </div>
      //   </>
      // ),
      // subscription: (
      //   <>
      //     <div className="ant-employed">
      //       <span>Ends in 2 month</span>
      //     </div>
      //   </>
      // ),
      city: (
        <>
          <div className="ant-employed">
            <span>{w?.city}</span>
            {/* <span>Audio</span> */}
          </div>
        </>
      ),
    };
  });
  // for user
  const data = useSelector((state) => state?.analytics?.UseranalyticsData);

  const monthdata = [];
  const date = new Date();
  const year = moment(date).format("YY");
  let month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  for (let i = 0; i < month.length; i++) {
    let com = data.filter(function (company) {
      if (company.createdAt === `${month[i]} ${year}`) {
        return true;
      }
    });
    monthdata.push(com.length);
  }
  const profilesdata = useSelector(
    (state) => state?.analytics?.userProfileAnalytics
  );

  // for user profile
  const profilemonthdata = [];
  const profiledate = new Date();
  const profileyear = moment(profiledate).format("YY");
  let profilemonth = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  for (let i = 0; i < profilemonth.length; i++) {
    let com = profilesdata.filter(function (company) {
      if (company.createdAt === `${profilemonth[i]} ${profileyear}`) {
        return true;
      }
    });
    profilemonthdata.push(com.length);
  }
  const dispatch = useDispatch();

  // useEffect(() => {
  //   return () => {
  //     socketsss.off();
  //   };
  // }, []);
  const consultantchats = useSelector((state) => state.user?.consultantChat);
  // console.log({ consultantchats });
  const joinChatroom = (w) => {
    socketsss.emit("join-room", ConsultantProfileData?._id + w?._id);
    // setactiveChat(w);
    // console.log("s", ConsultantProfileData?._id);
  };

  useEffect(() => {
    socketsss.on("message", (data) => {
      dispatch(getconsultatMessageByroomId(data.roomid));
      setlastmessageactive(data);
      console.log("in-coming message", data);

      addResponseMessage(data?.data);
      // if (first == true) {
      // }
    });
    return () => {
      socketsss.off("message");
    };
  }, []);
  const bbb = datas.find((e) => {
    {
      return e?.user?._id == lastmessageactive?.userid;
    }
  });
  useEffect(() => {
    if (bbb?.firstName) {
      setshowchatbox(true);
      setactiveChat(bbb);
    }

    return () => {};
  }, [bbb]);

  // console.log({ bbb });

  useEffect(() => {
    socketsss.emit("join-room", ConsultantProfileData?._id);

    dispatch(getUserList());
    dispatch(getUserProfileAnalytics());
    dispatch(getUserAnalytics());
  }, []);
  const searchhandelchange = (e) => {
    const keyword = e.target.value;
  };

  return (
    <>
      <div
        // onClick={() => {
        //   toggleWidget();
        // }}
        className="tabled"
      >
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <div className="layout-content">
              <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <UsersEChart month={monthdata} total={"Total Users"} />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <UserLinesChart
                      month={profilemonthdata}
                      total={"Total Profile"}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Users call history"
              extra={
                <Input
                  className="header-search"
                  onChange={searchhandelchange}
                  placeholder="Type here..."
                  prefix={<SearchOutlined />}
                />
              }
            >
              {" "}
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={datavalue}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Users;
