import React from "react";
import Main from "../components/layout/Main";

import Profile from "../pages/Profile";

import Users from "../pages/Users";
import Consultant from "../pages/Consultant";

import ConsultantCreate from "../pages/ConsultantCreate";
import ConsultantProfile from "../pages/ConsultantProfile";
import ConsultantProfiledetails from "../pages/ConsultantProfileDetails";
import Usercreate from "../pages/UserCreate";
import { Route, Routes } from "react-router-dom";
import Referalcode from "../pages/Referalcode";
import ConsultantCoinRedeem from "../pages/Redeem/RedeemCoin";

const admin = () => {
  return (
    <Main>
      <Routes>
        <Route exact path="/users" element={<Users />} />
        <Route exact path="/" element={<Users />} />
        <Route exact path="/profile/:id" element={<Profile />} />
        <Route exact path="/usercreate" element={<Usercreate />} />

        <Route exact path="/consultant" element={<Consultant />} />
        <Route exact path="/referalcode" element={<Referalcode />} />
        <Route
          exact
          path="/consultantcreate/:id"
          element={<ConsultantCreate />}
        />
        <Route
          exact
          path="/consultantprofile"
          element={<ConsultantProfile />}
        />
        <Route
          exact
          path="/consultantdetails/:id"
          element={<ConsultantProfiledetails />}
        />
        <Route exact path="/redeem-coin" element={<ConsultantCoinRedeem />} />
      </Routes>
    </Main>
  );
};

export default admin;
