// export const authToken =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiI1NGZkMjA2OS00NTJjLTQyNjMtYjM0Ni00NTlkZjQ1OTJmNmYiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTY5ODA1NTg5OCwiZXhwIjoxODU1ODQzODk4fQ.pR8dqB2-KqdnBD3qQQXL2prch2LFG4nhtJWVJYmv5aw";
export const authToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiI1MDNjYjBjYS03MGQ2LTQwNmYtYjc4MS1lZGVmM2U2Nzk2N2QiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTczMzM5MTA2NSwiZXhwIjoxODkxMTc5MDY1fQ.zrBsZKIV_xQweoOCTM7wMXdkqbCCReUacsN0yeeK_5g";
// API call to create meeting
export const createMeeting = async ({ token }) => {
  const res = await fetch(`https://api.videosdk.live/v2/rooms`, {
    method: "POST",
    headers: {
      authorization: `${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });

  const roomId = await res.json();
  console.log("Room created", roomId);
  return roomId?.roomId;
};
