import "./alert.css";
export const ConfirmationAlert = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="overlay">
      <div className="modal">
        <h2>{message}</h2>
        <div className="button-group">
          <button className="button" onClick={onConfirm}>
            Yes
          </button>
          <button className="button" onClick={onCancel}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};
