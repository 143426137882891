import SignIn from "./pages/SignIn";
import Admin from "./protectedRoutes/admin";
import Consultant from "./protectedRoutes/consultant";
import PrivateRoute from "./protectedRoutes/protector";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
// import AudioRingTone from "./assets/callaudio.mp3";

function App() {
  const token = JSON.parse(localStorage.getItem("userAuth"))?.role;
  // console.log("token: ", token);

  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        {/* <Route path="/" element={<Receive />} /> */}

        {!token && <Route path="/" element={<SignIn />} />}

        {token === "admin" && (
          <Route
            exact
            path="/*"
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          />
        )}
        {token === "consultant" && (
          <Route
            exact
            path="/*"
            element={
              <PrivateRoute>
                <Consultant />
              </PrivateRoute>
            }
          />
        )}

        {/* <Route
          exact
          path="/consultant*"
          element={
            <ProtectorConsultant>
              <Consultant />
            </ProtectorConsultant>
          }
        /> */}
        {/* <Route exact path="*" element={<Login />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
