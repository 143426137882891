import axios from "axios";
// export const url = "http://localhost:5001";
// export const url = "http://192.168.29.125:5001";

export const url = "https://backend.wannabae.in";
const token = localStorage.getItem("authorization");
console.log("data: ", token);
export default axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
    Authorization: JSON.parse(token),
  },

  // baseURL: "https://lexpedia-rest-api.herokuapp.com/api/v1",
});

export const SOCKET_URL = url;

// baseURL: "http://3.110.217.18:5000"
