import "./App.css";
import React, { useEffect, useRef, useState } from "react";
import {
  MeetingProvider,
  MeetingConsumer,
  useMeeting,
  useParticipant,
} from "@videosdk.live/react-sdk";
import "./s.css";
import { authToken } from "./APIToken";
import { useNavigate, useParams } from "react-router";
import { socketsss } from "../components/layout/consultantSidenav";
import AudioWaveView from "../components/audio-effect/audio";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";

function ParticipantView(props) {
  const micRef = useRef(null);
  const { micStream, micOn, isLocal } = useParticipant(props.participantId);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  return (
    <div key={props.participantId}>
      <AudioWaveView micRef={micRef} isLocal={isLocal} />
      {isLocal && <Controls participantId={props.participantId} />}
    </div>
  );
}

function Controls(props) {
  const navigate = useNavigate();
  const { leave, toggleMic } = useMeeting();
  const params = useParams();
  const handelLeave = () => {
    leave();
    const ConsultantProfileData = JSON.parse(
      localStorage.getItem("Consultantprofile")
    );
    socketsss.emit("endcall", {
      toUser: params.id,
      fromUser: ConsultantProfileData._id,
      consultantid: ConsultantProfileData._id,
      user: params.id,
    });
    socketsss.emit("endcalls", {
      toUser: ConsultantProfileData._id,
      fromUser: params.id,
      consultantid: ConsultantProfileData._id,
      user: params.id,
    });
    console.log("ended call");
    setTimeout(() => {
      navigate("/profile");
    }, 3000);
  };
  const { micOn } = useParticipant(props.participantId);

  useEffect(() => {
    socketsss.on("endcall", (data) => {
      console.log("callended", data);
      leave();

      setTimeout(() => {
        navigate("/profile");
      }, 3000);
    });

    return () => {
      socketsss.off("endcall");
    };
  }, []);

  return (
    <div className="flex gap-4 items-center justify-center p-4 bg-gray-800 rounded-lg shadow-md">
      {/* Leave Button */}
      <button
        onClick={() => handelLeave()}
        className="px-6 py-2 text-sm font-semibold text-white bg-red-600 rounded-lg shadow hover:bg-red-700 active:scale-95 transition-all"
      >
        <h1 className="text-white font-extrabold pt-2">Leave</h1>
      </button>

      {/* Toggle Mic Button */}
      <button
        onClick={() => toggleMic()}
        className="px-6 py-2 text-sm font-semibold text-white bg-green-600 rounded-lg shadow hover:bg-green-700 active:scale-95 transition-all"
      >
        {micOn ? (
          <FaMicrophone className="text-white" />
        ) : (
          <FaMicrophoneSlash className="text-white" />
        )}
        <h1 className="text-white font-extrabold pt-2">Mic</h1>
      </button>
    </div>
  );
}

function MeetingView(props) {
  const [joined, setJoined] = useState(null);
  const { join } = useMeeting();
  const { participants } = useMeeting({
    onMeetingJoined: () => {
      setJoined("JOINED");
    },
    onMeetingLeft: () => {
      props.onMeetingLeave();
    },
  });
  const joinMeeting = () => {
    setJoined("JOINING");
    join();
  };

  useEffect(() => {
    setTimeout(() => {
      joinMeeting();
    }, 2000);
  }, []);

  return (
    <div className="grid place-items-center modal-overlay">
      {joined && joined == "JOINED" ? (
        <div>
          {[...participants.keys()].map((participantId) => (
            <ParticipantView
              participantId={participantId}
              key={participantId}
            />
          ))}
        </div>
      ) : joined && joined == "JOINING" ? (
        <JoiningMeeting />
      ) : (
        <JoiningMeeting />
      )}
    </div>
  );
}

const JoiningMeeting = () => {
  return (
    <div className="flex items-center px-20 justify-center h-56  rounded-lg">
      <h1 className="text-2xl font-bold text-white tracking-wide flex items-center">
        Joining meeting
        <span className="ml-2 flex">
          <span className="animate-[bounce_1s_infinite]">.</span>
          <span className="animate-[bounce_1s_infinite] animation-delay-200">
            .
          </span>
          <span className="animate-[bounce_1s_infinite] animation-delay-400">
            .
          </span>
        </span>
      </h1>
    </div>
  );
};

function Oncall() {
  const [meetingId, setMeetingId] = useState(null);

  const onMeetingLeave = () => {
    setMeetingId(null);
  };
  const params = useParams();

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(true);
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        // Show a custom message when the user tries to leave the page
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    const ConsultantProfileData = JSON.parse(
      localStorage.getItem("Consultantprofile")
    );
    // Handle the user's choice when they click the "reload" button
    window.addEventListener("unload", () => {
      socketsss.emit("endcall", {
        toUser: params.id,
        fromUser: ConsultantProfileData._id,
        consultantid: ConsultantProfileData._id,
        user: params.id,
      });
      socketsss.emit("endcalls", {
        toUser: ConsultantProfileData._id,
        fromUser: params.id,
        consultantid: ConsultantProfileData._id,
        user: params.id,
      });
      // You can add your custom logic here to perform a specific task.
      // For example, save data or show a message before the page reloads.
      // Note that the options for custom actions are limited and may vary between browsers.
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    setMeetingId(params.meetingid);
  }, [params?.meetingid]);

  return authToken && params?.meetingid ? (
    <MeetingProvider
      config={{
        meetingId: params?.meetingid,
        micEnabled: true,
        webcamEnabled: false,
        name: "C.V. Raman",
      }}
      token={authToken}
    >
      <MeetingConsumer>
        {() => (
          <MeetingView
            meetingId={params?.meetingid}
            onMeetingLeave={onMeetingLeave}
          />
        )}
      </MeetingConsumer>
    </MeetingProvider>
  ) : (
    <JoiningMeeting />
  );
}

export default Oncall;
