import React from "react";

export default function Chatcard({
  e,
  setindex,
  index,
  ind,
  setlastindex,
  last,
  onClickUser,
}) {
  const selectedID = localStorage.getItem("selectedId");

  return (
    <div
      style={{
        border: `${selectedID === e?.user?._id ? "2px solid #A40165" : ""}`,
      }}
      onClick={() => {
        setlastindex(last);
        setindex(index);
        onClickUser();
        localStorage.setItem("index", index);
      }}
      className="usercard"
    >
      <div
        className="userimg"
        style={{
          backgroundImage: `url(${e.profilePic?.url})`,
        }}
      />
      <div className="username">{e?.firstName}</div>
      {e.chatlength.length > 0 && (
        <div className="chatlength">{e.chatlength.length}</div>
      )}
    </div>
  );
}
